body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f2f5 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    height: 100%;
}

.react-pdf__Page canvas {
    width: 100% !important;
    height: auto !important;
}

.react-pdf__Page .linkAnnotation {
   display: none;
}


/*DESCRIPTION LIST IN BENEFITS*/
@media only screen and (max-width: 480px) {
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right: 0;
    }

    .ant-descriptions-small .ant-descriptions-row > th,
    .ant-descriptions-small .ant-descriptions-row > td {
        display: block;
    }


    *::-webkit-scrollbar {
        display: none;
    }

    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }


}

.ant-modal-close-x {
    background: #FFF;
}

/* DUOTONE IMAGES */
:root {
    --base-blend:        #2f54eb;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --foreground:        #000000;
    --opacity:           1;
    --spacing:           0px;
}

.img-wrapper {
    background-color:    var(--base-blend);
    display:             flex;
    flex:                1 1 100%;
    height:              100%;
    overflow:            hidden;
    padding:             var(--spacing);
    position:            relative;
}

.img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
    position:            relative;
    width:               100%;
}

.img-wrapper::before {
    background-color:    var(--foreground);
    bottom:              0;
    content:             '';
    height:              100%;
    left:                0;
    mix-blend-mode:      var(--fg-blend);
    position:            absolute;
    right:               0;
    top:                 0;
    width:               100%;
    z-index:             1;
}
